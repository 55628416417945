import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { down } from '@thomaseckhardt/styled-breakpoints'
import config from '../utils/siteConfig'
import Layout from '../components2/Layout'
import SEO from '../components/SEO'
import Article, { VARIANT_TEXT_INTENDED } from '../modules/Article'
import Teaser from '../modules/Teaser'
import { VARIANT_PORTRAIT_LANDSCAPE_INTENDED } from '../components2/Puzzle'
import JumpNav from '../components2/JumpNav'

const Main = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  grid-template-rows: 1fr;

  > * {
    grid-column: 1 / span 16;
    min-height: 70vh;
  }

  > *:nth-child(2) {
    grid-row: 1;
    min-height: 0;
  }
`

const MainContent = styled.article`
  grid-column: 1 / span 16;
  min-height: 70vh;
`

const JumpNavContainer = styled.div`
  position: relative;
  grid-row: 1;
  grid-column: 1 / span 3;
  z-index: 1;

  ${down('laptop')} {
    display: none;
  }
`

const StyledJumpNav = styled(JumpNav)`
  position: sticky;
  top: 0;
  padding-top: ${props => props.theme.px('space.11')};
`

const Page = ({ data }) => {
  const postNode = {
    title: `Polstern - ${config.siteTitle}`,
  }

  const jumpNavItems = [
    {
      label: 'Bodenbeläge',
      href: '#bodenbelaege',
      active: true
    },
    {
      label: 'Designbodenbeläge',
      href: '#designbodenbelaege'
    },
    {
      label: 'Linoleum',
      href: '#linoleum'
    },
    {
      label: 'PVC',
      href: '#pvc'
    },
    {
      label: 'Parkett',
      href: '#parkett'
    },
    {
      label: 'Teppichboden',
      href: '#teppichboden'
    },
    {
      label: 'Kork',
      href: '#kork'
    },
  ]

  const blockIntro = {
    leading: 10,
    variant: VARIANT_TEXT_INTENDED,
    id: `bodenbelaege`,
    headline: `Bodenbeläge`,
    lead: `Bodenbeläge sind bei uns nicht einfach nur die letzte Schicht des Fußbodenaufbaus – vielmehr tragen diese maßgeblich zu der Gestaltung und Nutzung der jeweiligen Räumlichkeiten bei. In der Auswahl des richtigen Bodenbelages finden wir gemeinsam mit Ihnen die beste Lösung für Ihren Wohnraum und Ihr Budget.`,
    puzzle: {
      caption: `Designböden aus der Serie Ballerina von Ranch`,
      variant: VARIANT_PORTRAIT_LANDSCAPE_INTENDED,
      items: [
        {
          fluid: data.intro1.childImageSharp.fluid
        },
        {
          fluid: data.intro2.childImageSharp.fluid
        }
      ]
    }
  }

  const blockDesign = {
    leading: 10,
    id: 'designbodenbelaege',
    headline: 'Designbodenbeläge',
    subline: 'für nahezu jeden Einsatzbereich geschaffen',
    copytext: `Designbeläge zeichnen sich durch eine täuschend echte Optik und hohe Widerstandsfähigkeit aus. Die sogenannten LVTs (luxury vinyl tiles) können nahezu jedes Naturmaterial abbilden. Ob Holz- oder Steinoptik, sie werden kaum einen Unterschied zum echten Material feststellen. Trotzdem haben sie ein sehr strapazierfähiges Material, das sich problemlos reinigen lässt und wasserbeständig ist. Wir verlegen Designbeläge der Marken Süd Bund, Objectflor und Designflooring.`,
    variant: VARIANT_TEXT_INTENDED
  }
  const blockLinoleum = {
    leading: 10,
    id: 'linoleum',
    headline: 'Linoleum',
    subline: 'der naturverbundene Bodenbelag',
    copytext: `Oft wird Linoleum mit PVC verwechselt, dabei handelt es sich hier um ein reines Naturprodukt. Hergestellt aus Leinöl, Kork, Holz und weiteren natürlichen Stoffen, weist Linoleum eine hohe Widerstandsfähigkeit auf und ist leicht zu reinigen.`,
    variant: VARIANT_TEXT_INTENDED
  }
  const blockPVC = {
    leading: 10,
    id: 'pvc',
    headline: 'PVC',
    subline: 'der Bodenbelag für anspruchsvolle Einsatzbereiche',
    copytext: `PVC (steht als Abkürzung für Polyvinylchlorid) ist nicht nur sehr strapazierfähig, sondern bietet auch weitere Zusatznutzen wie elektrische Ableitfähigkeit. Daher wird er gerne in Operationsräumen oder unter Arbeitsplätze für die Arbeit an Mikroelektronik verlegt. Im Vergleich zu einem Linoleum ist ein PVC kostengünstiger und auch leichter zu verlegen.`,
    variant: VARIANT_TEXT_INTENDED
  }
  const blockParkett = {
    leading: 10,
    id: 'parkett',
    headline: 'Parkett',
    subline: 'hochwertig und elegant',
    copytext: `Der Klassiker unter den Naturböden bietet eine unverkennbare, schöne Optik. Wir liefern und verlegen alles vom Fischgrat bis hin zur XXL Landhausdiele. Hierbei arbeiten wir gerne mit Herstellern aus der Region zusammen und freuen uns, Ihnen auch FSC-zertifiziertes Parkett anbieten zu können.`,
    variant: VARIANT_TEXT_INTENDED
  }
  const blockTeppichboden = {
    leading: 10,
    id: 'teppichboden',
    headline: 'Teppichboden',
    subline: 'Komfort für Ihre Füße',
    copytext: `Für viele Einsatzbereiche, wie Treppen, Flure, Wohn- oder Schlafzimmer ist der textile Bodenbelag eine gute Entscheidung und gibt ein wohliges und gemütliches Gefühl. Auch Allergiker können aufatmen, denn im Vergleich zum Glattboden ist die Feinstaubbelastung bei einem Teppichboden nur halb so hoch. Sie finden bei uns Bahnenware oder Fliesen hergestellt aus Wolle, Seide oder Kunstfaser. Zudem bieten wir die Verlegeart des Teppichverspannens, bei der der Teppichboden im jeweiligen Raum mittels Nagelleisten und Spanngerät verspannt wird. Durch die verwendete Unterlage erhalten Sie einen wunderbaren Gehkomfort und der Teppich wird geringer abgenutzt.`,
    variant: VARIANT_TEXT_INTENDED
  }
  const blockKork = {
    leading: 10,
    id: 'kork',
    headline: 'Kork',
    subline: 'keine Spur von Öko-Look',
    copytext: `Kork ist als nachhaltiges Naturmaterial nicht wegzudenken – mit seinen dämmenden Eigenschaften in Bereich Wärme oder Trittschall ist er immer die richtige Wahl. Da er besonders elastisch ist, ist es hier besonders angenehm barfuß zu laufen. Kork kann mittlerweile in verschieden Designs geliefert werden und hat sein Image vom einseitigen Öko-Boden abgelegt.`,
    variant: VARIANT_TEXT_INTENDED
  }

  const serviceTeaser = {
    leading: 10,
    topline: `Unser Kundenservice`,
    headline: `Wir verlegen, schleifen und versiegeln Ihren Boden`,
    action: {
      to: `/kundenservice#boden`
    },
    image: {
      objectPosition: `100% 100%`,
      fluid: data.serviceTeaser.childImageSharp.fluid
    }
  }

  return (
    <Layout>
      <Helmet>
        <title>{`Polstern - ${config.siteTitle}`}</title>
      </Helmet>
      <SEO postNode={postNode} pagePath="polstern" customTitle />

      <Main>
        <JumpNavContainer>
          <StyledJumpNav items={jumpNavItems} />
        </JumpNavContainer>
        <MainContent>
          <Article as="header" {...blockIntro}></Article>
          <Article as="article" {...blockDesign}></Article>
          <Article as="article" {...blockLinoleum}></Article>
          <Article as="article" {...blockPVC}></Article>
          <Article as="article" {...blockParkett}></Article>
          <Article as="article" {...blockTeppichboden}></Article>
          <Article as="article" {...blockKork}></Article>
        </MainContent>
      </Main>
      <Teaser
        as="aside"
        {...serviceTeaser} />
    </Layout>
  )
}

export default Page

export const pageQuery = graphql`
  query {
    intro1: file(relativePath: { eq: "boden-design-RS60586_Ranch_ballerina.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1280) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    intro2: file(relativePath: { eq: "boden-design-RS62260_Ballerina_152x762_C55_2019.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1280) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    carpets1: file(relativePath: { eq: "boden-teppich-einfassungen.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 640) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    serviceTeaser: file(relativePath: { eq: "service-boden-22.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2560) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
